.carousell{
    width: 100vw;
    height: 40vw;
}
.imgg{
    width: 100vw;
    height: 40vw;
}

.carousellCompro{
    margin-left: 20vw;
    width: 60vw;
    height: 40vw;
}
.carousellInmo{
    overflow-y: hidden;
    max-width: 600px;
    max-height: 452px;
    border-radius: 25px;
    box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
    width: 40vw;
    height: 30vw;
    margin-bottom: 2rem;
}
.imggCrompo{
    width: 60vw;
    height: 40vw;
}
.imggInmo{
    width: 610px;
    height: 456px;
}
.Compromisoo h2{
    text-transform: uppercase;
    font-weight: bolder;
    }

.Compromisoo a{
    font-size: 20px;
    text-align: justify;
}
@media screen and (max-width: 990px) {
    .carousellCompro{
        margin-left:0;
        width: 100vw;
        height: 60vw;
    }
    .imggCrompo{
        width: 100vw;
        height: 60vw;
    }
    .carousellInmo{
        width: 100vw;
        height: 60vw;
    }
    .imggInmo{
        width: 100vw;
        height: 60vw;
    }
}


.navbar{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    border-bottom: solid silver;
    padding-top: 5px;
    padding-bottom: 5px;

}
.navbar img{
    max-width: 3vw;
}
.textL{
    text-decoration: none;
    color: #21245c;
    font-family: 'Cinzel', serif;
    font-size: 25px;
    text-align: center;

}

.itemss{
    justify-content:right;
    font-family: 'Cinzel', serif;
}
.itemss li:hover{
    color: #21245c;
}
.logo{
    width: 25rem;
    margin-left: 2vw;
    color: #21245c;
}
.logo p{
    margin-bottom: 0;
}
.logo img{
    margin-right: 2vw;

}
.navbar .logo{
    display: flex;
    align-items: center;
    font-family: 'Cinzel', serif;
    font-size: 25px;
}

@media screen and (max-width: 990px) {
    .logo{
        width: 70%;
        margin-left: 2vw;
        color: #21245c;
        justify-content: center;
        
    }
    .logo p{
        font-size: 30px !important;
    }
    
    .navbar img{
        max-width: 8vw;
    }
    
}
@media screen and (max-width: 490px) {
    .logo{
        width: 70%;
        margin-left: 2vw;
        color: #21245c;
        justify-content: center;
        
    }
    .logo p{
        font-size: 20px !important;
        font-weight: bolder;
    }
    
    .navbar img{
        max-width: 12vw;
    }
    
}
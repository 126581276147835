.Footer{
    display: grid;
    grid-auto-rows: minmax(250px);
    grid-template-columns: repeat(auto-fill,minmax(min(100%,17rem), 272px));
    border-top: solid gainsboro;
    padding-top: 1rem;
    gap: 1rem;
    background-color: #D9F7FF;
    height: auto;
    padding-inline: 45px;
}

.SectionV a{
    display: block;
    text-decoration: none;
    color: black;
    margin-bottom: 15px;
    
}
.SectionV{
    max-width: 20vw;
    min-width: 150px;
    overflow-y: hidden;
}
.Direccion{
    max-width: 20vw;
}
.info{
    max-width: 20vw;

}
.info .t{
    text-decoration: none;
    display: block;
    color: black;
}
.imag{
    grid-column: 1 / 3;
    max-width: 25rem;
    width: 30vw;
    min-width: 300px;
    margin-left: 15%;
}
.whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
  }
  
  @media screen and (max-width: 990px) {
    .imag{
        grid-column: 1;
    }
    .Direccion{
        max-width: 100vw;
    }
    .info{
        max-width: 100vw;
    
    }
    }
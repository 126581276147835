.Contactenoss{
    display: flex;
    align-items: center;

}
.Contactenoss a{
    max-width: 40vw;
    padding-left: 6vw;
    padding-right: 6vw;
}

.buttom{
    width: 80px;
    height: 30px;
    border: 1px;
    border-style:outset;
    background-color: rgba(16, 21, 179, 0.783);
    color: white;
}
.Formularioo{
    margin-top: 0.5vw;
    padding: 2VW;
    width: 400px;
    border: 1px solid;
    border-radius: 15px;
    /* box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603); */
    background-color: white;
    /* margin-left: 0.5rem; */
}
.Formularioo form{
    display: grid;
}
input{
    margin-top: 10px;
    border: inset;
    border-radius: 5px;
}
textarea{
    margin-top: 10px;
    height: 3vw;
    border: inset;
    border-radius: 5px;
}
@media screen and (max-width: 550px) {
    .Formularioo{
        width: 95%;
    }
    }
@media screen and (max-width: 900px) {
    textarea{
    
        height: 50px;
        border: inset;
        border-radius: 5px;
    }
}
.contenedorInmo{
    display: grid;
    grid-template-columns: repeat(2,minmax(25rem, 1fr));  
    grid-auto-rows: 31rem;
    justify-content: center;
    padding-inline: 75px;
}

.tituloInmo{
    font-weight: bolder
}

.EstadoInmo{
    font-size: 20px;
}

.precioInmo{
    font-weight: bolder;
    font-size: 200%;
    margin-top: 5vw;
    }

@media screen and (max-width: 990px) {
    .Equipoo{
        padding-inline: 0px;
    }
    .contenedorInmo{
        display: grid;
        grid-template-columns: repeat(1,minmax(25rem, 1fr));  
        grid-auto-rows: auto;
        justify-content: center;
    }
    .TextImno{
        display: grid;
        
        padding-inline: 25px;
       
    }
    .tituloInmo{
        justify-content: center;
        font-size: 20px;
        width: 370px;
    }
}
.ServiceCard{
    display: grid;
    grid-template-columns: repeat(2,minmax(25rem, 456px));  
    align-items: center;
    justify-content: space-around;
}
.ServiceCard img{
    width: 460px;
    height: 460px;
    border-radius: 15px;
    margin-top: 15px;
    box-shadow: -20px 10px 5px rgba(16, 22, 179, 0.603);
    margin-bottom: 1rem;
}
.ServiceCard2{
    display: grid;
    grid-template-columns: repeat(2,minmax(25rem, 456px));  
    align-items: center;
    justify-content: space-around;
   
    
}
.ServiceCard2 img{
    width: 460px;
    height: 460px;
    border-radius: 15px;
    box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
    margin-bottom: 1rem;
}
.ServiceCard2 .lista{
    grid-column: 1;
    grid-row: 1;
}
ul{
    list-style: none;
}
.lista{
    max-width: 45vw;
    min-width: 100%;

}
.lista li{
    margin-top: 1vw;
    border-bottom: 1px solid gainsboro;
 
    
}
.itemS::before{
    content: "ψ"; 
    padding-right: 6px;
    color: rgba(16, 21, 179, 0.783);;
}
.Service{
    width: 93vw;
    margin-left: 3vw;
}
.Cardss{
    margin-left: 4vw;
}

.Cardss h2{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bolder;
}

.Cardss li{
    font-size: 20px;
}
.Service h2{
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bolder;
}
.Service a{
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    .ServiceCard{
        grid-template-columns: repeat(1,minmax(25rem, 456px)); 
    }
    .ServiceCard2{
        grid-template-columns: repeat(1,minmax(25rem, 456px)); 
    }
    .ServiceCard2 img{
        grid-row: 1;
    }
    .ServiceCard2 .lista{
        grid-row: 2;
    }
    .lista{
        padding-left: 20px;
        padding-right: 50px;
    }
    .itemS{
        max-width: 100%;
  
    }
    .Cardss h2{
        font-size: 2rem;
    }
    .Service h2{
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: bolder;
    }
}
@media screen and (max-width: 300px) {
    .lista li{
        font-size: 15px;
        padding-left: 25px;
    }
}
@media screen and (max-width: 500px) {
    .Service h2{
        font-size: 30px;
    }
    .Service a{
        font-size: 15px;
    }
    .lista li{
        font-size: 15px;
    }
    .lista{
        padding-inline: 30px;
    }
    .ServiceCard img{
        width: 370px;
        height: 370px;
    }
    .ServiceCard2 img{
        width: 370px;
        height: 370px;
    box-shadow: -20px 10px 5px rgba(16, 22, 179, 0.603);

    }
}
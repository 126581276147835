
.navbar{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    border-bottom: solid silver;
    padding-top: 5px;
    padding-bottom: 5px;

}
.navbar img{
    max-width: 3vw;
}
.textL{
    text-decoration: none;
    color: #21245c;
    font-family: 'Cinzel', serif;
    font-size: 25px;
    text-align: center;

}

.itemss{
    justify-content:right;
    font-family: 'Cinzel', serif;
}
.itemss li:hover{
    color: #21245c;
}
.logo{
    width: 25rem;
    margin-left: 2vw;
    color: #21245c;
}
.logo p{
    margin-bottom: 0;
}
.logo img{
    margin-right: 2vw;

}
.navbar .logo{
    display: flex;
    align-items: center;
    font-family: 'Cinzel', serif;
    font-size: 25px;
}

@media screen and (max-width: 990px) {
    .logo{
        width: 70%;
        margin-left: 2vw;
        color: #21245c;
        justify-content: center;
        
    }
    .logo p{
        font-size: 30px !important;
    }
    
    .navbar img{
        max-width: 8vw;
    }
    
}
@media screen and (max-width: 490px) {
    .logo{
        width: 70%;
        margin-left: 2vw;
        color: #21245c;
        justify-content: center;
        
    }
    .logo p{
        font-size: 20px !important;
        font-weight: bolder;
    }
    
    .navbar img{
        max-width: 12vw;
    }
    
}
.Footer{
    display: grid;
    grid-auto-rows: minmax(250px);
    grid-template-columns: repeat(auto-fill,minmax(min(100%,17rem), 272px));
    border-top: solid gainsboro;
    padding-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #D9F7FF;
    height: auto;
    padding-inline: 45px;
}

.SectionV a{
    display: block;
    text-decoration: none;
    color: black;
    margin-bottom: 15px;
    
}
.SectionV{
    max-width: 20vw;
    min-width: 150px;
    overflow-y: hidden;
}
.Direccion{
    max-width: 20vw;
}
.info{
    max-width: 20vw;

}
.info .t{
    text-decoration: none;
    display: block;
    color: black;
}
.imag{
    grid-column: 1 / 3;
    max-width: 25rem;
    width: 30vw;
    min-width: 300px;
    margin-left: 15%;
}
.whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
  }
  
  @media screen and (max-width: 990px) {
    .imag{
        grid-column: 1;
    }
    .Direccion{
        max-width: 100vw;
    }
    .info{
        max-width: 100vw;
    
    }
    }
.Wrapper{
  width: 100vw;
  font-family: 'Cinzel', serif;
}
::-webkit-scrollbar {
  background: #ccc;
  border-radius: 4px;
  width: 5px;
   /* Ocultar scroll */
}
::-webkit-scrollbar-thumb {
  background: rgba(18, 2, 235, 0.199);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(18, 2, 235, 0.655);
  display: visible;
}
*{
  overflow-x: hidden;
}
h2{
  margin-top: 20px;
  overflow-y: hidden;
}
h3,h4{
  overflow-y: hidden;
}
.mapa{
  width: 457px;
  height: 457px;
  border-radius: 20px;
  box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
  margin-bottom: 30px;
}
.contenedorMapa{
  display: grid;
  grid-template-columns: repeat(2,minmax(25rem, 456px));  
  grid-auto-rows: 29rem;
  justify-content: center;
}
.CuadroTextMap{
  max-width: 450px;
  background-color: #D9F7FF;
  border-top-left-radius: 20px  ;
  border-bottom-left-radius: 20px;
  height: 29rem;
  font-family: "Palatino";
  font-size: 16px;
  text-align: justify;
  padding-top: 1vw;
  padding-inline: 20px;
}
@media screen and (max-width: 990px) {
.contenedorMapa{
  grid-template-columns: repeat(1,minmax(25rem, 456px));  

}
.CuadroTextMap{
  border-radius: 20px;
}
}
@media screen and (max-width: 550px) {
  .mapa{
    width:  90%;
    height: 90%;
  }

  }

.card{
    display: grid;
    height: 19rem;
    
    border: none;
    font-size: 23px;
    font-family: "Palatino";
    text-align: center;
    background-color: #D9F7FF ;
    box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
   
}
.minWrapper{
    display: grid;
    grid-auto-rows: 20rem;
    grid-template-columns: repeat(auto-fill,minmax(20rem, 1fr));
    grid-gap: 2rem;
    padding-left: 8%;
    padding-right: 8%;
}
.card a{
    overflow-y:hidden;
}

.card:hover{
    /* animation: fadeIn 1s; */
    background: linear-gradient(150deg, #029eff,  #ffffff, #029eff);
    background-size: 400% 300%;
    -webkit-animation: BgGradient 5s ease infinite;
            animation: BgGradient 5s ease infinite;
}
.card img{
    width: 153px;
    height: 153px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    background-color: transparent;
}


.fila{
    margin-bottom: 4vw;
    display: flex;
}
.SubTitle{
    text-align: center;
    font-size: 31px;
    font-weight:600;
    color: #21245c;
}
@-webkit-keyframes BgGradient {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
@keyframes BgGradient {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
@-webkit-keyframes fadeIn {
    0% {
    background: radial-gradient(aqua 0, white 95%); }
    10% {
        background:radial-gradient(aqua 10%,white 90%)}
    20% {
        background:radial-gradient(aqua 20%, white)}
    30% {
        background:radial-gradient(aqua 30%, white)}
    40% {
        background:radial-gradient(aqua 40%, white)}
    50% {
        background:radial-gradient(aqua 50%, white)}
    60% {
        background:radial-gradient(aqua 60%, white)}
    70% {
        background:radial-gradient(aqua 70%, white)} 
    80% {
        background:radial-gradient(aqua 80%, white)} 
    90% {
        background:radial-gradient(aqua 90%, white)}     
    100% {
    background:radial-gradient(aqua 100%, white)}
  }
@keyframes fadeIn {
    0% {
    background: radial-gradient(aqua 0, white 95%); }
    10% {
        background:radial-gradient(aqua 10%,white 90%)}
    20% {
        background:radial-gradient(aqua 20%, white)}
    30% {
        background:radial-gradient(aqua 30%, white)}
    40% {
        background:radial-gradient(aqua 40%, white)}
    50% {
        background:radial-gradient(aqua 50%, white)}
    60% {
        background:radial-gradient(aqua 60%, white)}
    70% {
        background:radial-gradient(aqua 70%, white)} 
    80% {
        background:radial-gradient(aqua 80%, white)} 
    90% {
        background:radial-gradient(aqua 90%, white)}     
    100% {
    background:radial-gradient(aqua 100%, white)}
  }
  @media screen and (max-width: 990px) {
    .SubTitle{
        font-size: 25px;
    }
    
}
.Contactenoss{
    display: flex;
    align-items: center;

}
.Contactenoss a{
    max-width: 40vw;
    padding-left: 6vw;
    padding-right: 6vw;
}

.buttom{
    width: 80px;
    height: 30px;
    border: 1px;
    border-style:outset;
    background-color: rgba(16, 21, 179, 0.783);
    color: white;
}
.Formularioo{
    margin-top: 0.5vw;
    padding: 2VW;
    width: 400px;
    border: 1px solid;
    border-radius: 15px;
    /* box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603); */
    background-color: white;
    /* margin-left: 0.5rem; */
}
.Formularioo form{
    display: grid;
}
input{
    margin-top: 10px;
    border: inset;
    border-radius: 5px;
}
textarea{
    margin-top: 10px;
    height: 3vw;
    border: inset;
    border-radius: 5px;
}
@media screen and (max-width: 550px) {
    .Formularioo{
        width: 95%;
    }
    }
@media screen and (max-width: 900px) {
    textarea{
    
        height: 50px;
        border: inset;
        border-radius: 5px;
    }
}
.ServiceCard{
    display: grid;
    grid-template-columns: repeat(2,minmax(25rem, 456px));  
    align-items: center;
    justify-content: space-around;
}
.ServiceCard img{
    width: 460px;
    height: 460px;
    border-radius: 15px;
    margin-top: 15px;
    box-shadow: -20px 10px 5px rgba(16, 22, 179, 0.603);
    margin-bottom: 1rem;
}
.ServiceCard2{
    display: grid;
    grid-template-columns: repeat(2,minmax(25rem, 456px));  
    align-items: center;
    justify-content: space-around;
   
    
}
.ServiceCard2 img{
    width: 460px;
    height: 460px;
    border-radius: 15px;
    box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
    margin-bottom: 1rem;
}
.ServiceCard2 .lista{
    grid-column: 1;
    grid-row: 1;
}
ul{
    list-style: none;
}
.lista{
    max-width: 45vw;
    min-width: 100%;

}
.lista li{
    margin-top: 1vw;
    border-bottom: 1px solid gainsboro;
 
    
}
.itemS::before{
    content: "\3C8"; 
    padding-right: 6px;
    color: rgba(16, 21, 179, 0.783);;
}
.Service{
    width: 93vw;
    margin-left: 3vw;
}
.Cardss{
    margin-left: 4vw;
}

.Cardss h2{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bolder;
}

.Cardss li{
    font-size: 20px;
}
.Service h2{
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bolder;
}
.Service a{
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    .ServiceCard{
        grid-template-columns: repeat(1,minmax(25rem, 456px)); 
    }
    .ServiceCard2{
        grid-template-columns: repeat(1,minmax(25rem, 456px)); 
    }
    .ServiceCard2 img{
        grid-row: 1;
    }
    .ServiceCard2 .lista{
        grid-row: 2;
    }
    .lista{
        padding-left: 20px;
        padding-right: 50px;
    }
    .itemS{
        max-width: 100%;
  
    }
    .Cardss h2{
        font-size: 2rem;
    }
    .Service h2{
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: bolder;
    }
}
@media screen and (max-width: 300px) {
    .lista li{
        font-size: 15px;
        padding-left: 25px;
    }
}
@media screen and (max-width: 500px) {
    .Service h2{
        font-size: 30px;
    }
    .Service a{
        font-size: 15px;
    }
    .lista li{
        font-size: 15px;
    }
    .lista{
        padding-inline: 30px;
    }
    .ServiceCard img{
        width: 370px;
        height: 370px;
    }
    .ServiceCard2 img{
        width: 370px;
        height: 370px;
    box-shadow: -20px 10px 5px rgba(16, 22, 179, 0.603);

    }
}
.cardE{
    width: 460px;
    display: grid;
    padding-left: 3vw;
    padding-right: 3vw;
    grid-row-gap: 10px;
    row-gap: 10px;
}
.cardE img{
    width: 305px;
    margin: auto;
    border-style: none;
    border-radius: 200px;
    background-color: transparent;
    padding-bottom: 15px;
}
.cardE img:hover{
    width: 350px;
    
}
.Equipoo{
    display: grid;
    grid-auto-rows: 28rem;
    grid-template-columns: repeat(auto-fill,minmax(min(100%,25rem), 1fr));
    padding-inline: 45px;
    grid-column-gap: 77px;
    -webkit-column-gap: 77px;
            column-gap: 77px;
}
.btnn{
    background-color: transparent;
    border: none;
}
.modal-body {
    text-align: justify;
    font-family: 'Tiro Devanagari Hindi', serif;
}

.modal-header p{
    margin-bottom: 0px;
}

@media screen and (max-width: 990px) {
    .Equipoo{
        padding-inline: 0px;
    }
    }

@media screen and (max-width: 550px) {
    .cardE{
        width: 100%;
        
    }
    }
.carousell{
    width: 100vw;
    height: 40vw;
}
.imgg{
    width: 100vw;
    height: 40vw;
}

.carousellCompro{
    margin-left: 20vw;
    width: 60vw;
    height: 40vw;
}
.carousellInmo{
    overflow-y: hidden;
    max-width: 600px;
    max-height: 452px;
    border-radius: 25px;
    box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
    width: 40vw;
    height: 30vw;
    margin-bottom: 2rem;
}
.imggCrompo{
    width: 60vw;
    height: 40vw;
}
.imggInmo{
    width: 610px;
    height: 456px;
}
.Compromisoo h2{
    text-transform: uppercase;
    font-weight: bolder;
    }

.Compromisoo a{
    font-size: 20px;
    text-align: justify;
}
@media screen and (max-width: 990px) {
    .carousellCompro{
        margin-left:0;
        width: 100vw;
        height: 60vw;
    }
    .imggCrompo{
        width: 100vw;
        height: 60vw;
    }
    .carousellInmo{
        width: 100vw;
        height: 60vw;
    }
    .imggInmo{
        width: 100vw;
        height: 60vw;
    }
}

.imagesAdmin{
    width: 100px;
}
.pruebaa{
    width: 5vw;
}
.Formulariooo{
    width: 40%;
    display: flex;
    flex-direction: column;
    max-block-size: 25rem;
}
.contenedorInmo{
    display: grid;
    grid-template-columns: repeat(2,minmax(25rem, 1fr));  
    grid-auto-rows: 31rem;
    justify-content: center;
    padding-inline: 75px;
}

.tituloInmo{
    font-weight: bolder
}

.EstadoInmo{
    font-size: 20px;
}

.precioInmo{
    font-weight: bolder;
    font-size: 200%;
    margin-top: 5vw;
    }

@media screen and (max-width: 990px) {
    .Equipoo{
        padding-inline: 0px;
    }
    .contenedorInmo{
        display: grid;
        grid-template-columns: repeat(1,minmax(25rem, 1fr));  
        grid-auto-rows: auto;
        justify-content: center;
    }
    .TextImno{
        display: grid;
        
        padding-inline: 25px;
       
    }
    .tituloInmo{
        justify-content: center;
        font-size: 20px;
        width: 370px;
    }
}
.boton{
    margin-top: 20px;
}
.formm{
    margin: 10vw;
}


.card{
    display: grid;
    height: 19rem;
    
    border: none;
    font-size: 23px;
    font-family: "Palatino";
    text-align: center;
    background-color: #D9F7FF ;
    box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
   
}
.minWrapper{
    display: grid;
    grid-auto-rows: 20rem;
    grid-template-columns: repeat(auto-fill,minmax(20rem, 1fr));
    grid-gap: 2rem;
    padding-left: 8%;
    padding-right: 8%;
}
.card a{
    overflow-y:hidden;
}

.card:hover{
    /* animation: fadeIn 1s; */
    background: linear-gradient(150deg, #029eff,  #ffffff, #029eff);
    background-size: 400% 300%;
    animation: BgGradient 5s ease infinite;
}
.card img{
    width: 153px;
    height: 153px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    background-color: transparent;
}


.fila{
    margin-bottom: 4vw;
    display: flex;
}
.SubTitle{
    text-align: center;
    font-size: 31px;
    font-weight:600;
    color: #21245c;
}
@keyframes BgGradient {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
@keyframes fadeIn {
    0% {
    background: radial-gradient(aqua 0, white 95%); }
    10% {
        background:radial-gradient(aqua 10%,white 90%)}
    20% {
        background:radial-gradient(aqua 20%, white)}
    30% {
        background:radial-gradient(aqua 30%, white)}
    40% {
        background:radial-gradient(aqua 40%, white)}
    50% {
        background:radial-gradient(aqua 50%, white)}
    60% {
        background:radial-gradient(aqua 60%, white)}
    70% {
        background:radial-gradient(aqua 70%, white)} 
    80% {
        background:radial-gradient(aqua 80%, white)} 
    90% {
        background:radial-gradient(aqua 90%, white)}     
    100% {
    background:radial-gradient(aqua 100%, white)}
  }
  @media screen and (max-width: 990px) {
    .SubTitle{
        font-size: 25px;
    }
    
}
.cardE{
    width: 460px;
    display: grid;
    padding-left: 3vw;
    padding-right: 3vw;
    row-gap: 10px;
}
.cardE img{
    width: 305px;
    margin: auto;
    border-style: none;
    border-radius: 200px;
    background-color: transparent;
    padding-bottom: 15px;
}
.cardE img:hover{
    width: 350px;
    
}
.Equipoo{
    display: grid;
    grid-auto-rows: 28rem;
    grid-template-columns: repeat(auto-fill,minmax(min(100%,25rem), 1fr));
    padding-inline: 45px;
    column-gap: 77px;
}
.btnn{
    background-color: transparent;
    border: none;
}
.modal-body {
    text-align: justify;
    font-family: 'Tiro Devanagari Hindi', serif;
}

.modal-header p{
    margin-bottom: 0px;
}

@media screen and (max-width: 990px) {
    .Equipoo{
        padding-inline: 0px;
    }
    }

@media screen and (max-width: 550px) {
    .cardE{
        width: 100%;
        
    }
    }
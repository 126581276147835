.Wrapper{
  width: 100vw;
  font-family: 'Cinzel', serif;
}
::-webkit-scrollbar {
  background: #ccc;
  border-radius: 4px;
  width: 5px;
   /* Ocultar scroll */
}
::-webkit-scrollbar-thumb {
  background: rgba(18, 2, 235, 0.199);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(18, 2, 235, 0.655);
  display: visible;
}
*{
  overflow-x: hidden;
}
h2{
  margin-top: 20px;
  overflow-y: hidden;
}
h3,h4{
  overflow-y: hidden;
}
.mapa{
  width: 457px;
  height: 457px;
  border-radius: 20px;
  box-shadow: 20px 10px 5px rgba(16, 22, 179, 0.603);
  margin-bottom: 30px;
}
.contenedorMapa{
  display: grid;
  grid-template-columns: repeat(2,minmax(25rem, 456px));  
  grid-auto-rows: 29rem;
  justify-content: center;
}
.CuadroTextMap{
  max-width: 450px;
  background-color: #D9F7FF;
  border-top-left-radius: 20px  ;
  border-bottom-left-radius: 20px;
  height: 29rem;
  font-family: "Palatino";
  font-size: 16px;
  text-align: justify;
  padding-top: 1vw;
  padding-inline: 20px;
}
@media screen and (max-width: 990px) {
.contenedorMapa{
  grid-template-columns: repeat(1,minmax(25rem, 456px));  

}
.CuadroTextMap{
  border-radius: 20px;
}
}
@media screen and (max-width: 550px) {
  .mapa{
    width:  90%;
    height: 90%;
  }

  }
.imagesAdmin{
    width: 100px;
}
.pruebaa{
    width: 5vw;
}
.Formulariooo{
    width: 40%;
    display: flex;
    flex-direction: column;
    max-block-size: 25rem;
}